import * as Toast from "@radix-ui/react-toast"
import { ReactNode } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { ErrorToast, SuccessToast } from "./components"
import { ToastType, openChange } from "./slice"
interface ToasterProps {
  children?: ReactNode
}

export default function Toaster({ children }: ToasterProps) {
  const dispatch = useAppDispatch()
  const { open, toast } = useAppSelector((state) => state.toaster)

  return (
    <>
      <Toast.Provider swipeDirection="right">
        <Toast.Root
          className="ToastRoot"
          duration={5000}
          onOpenChange={(o) => dispatch(openChange(o))}
          open={open}
        >
          {toast && toast.type !== ToastType.SUCCESS && (
            <ErrorToast toast={toast} />
          )}
          {toast?.type === ToastType.SUCCESS && <SuccessToast toast={toast} />}
        </Toast.Root>
        <Toast.Viewport asChild>
          <div className="bottom-0 max-w-[100vw] p-4 fixed right-0 w-[632px] z-50" />
        </Toast.Viewport>
      </Toast.Provider>
      {children}
    </>
  )
}
