"use client";

import { type ReactNode } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCircleExclamation,
  faInfoCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { Text } from "@radix-ui/themes";

interface AlertProps {
  children?: string;
  icon?: IconDefinition;
  lowerContent?: ReactNode;
  severity?: "error" | "warning" | "success";
  title?: string;
}

export default function Alert({
  children,
  icon,
  lowerContent,
  severity,
  title,
}: AlertProps) {
  return (
    <div
      className={clsx("bg-panel overflow-hidden bg-gray-1", {
        "text-red-11 border border-red-5": severity === "error",
        "text-yellow-11 border border-yellow-5": severity === "warning",
        "text-green-11 border border-green-5": severity === "success",
      })}
      role="alert"
    >
      <div
        className={clsx("flex py-0 px-5 gap-3", {
          "bg-redA-3": severity === "error",
          "bg-yellowA-3": severity === "warning",
          "bg-greenA-3": severity === "success",
        })}
      >
        <div className="py-5 z-10">
          <div className="h-5 w-5 flex items-center justify-center">
            <FontAwesomeIcon
              icon={
                icon ||
                (severity === "error" ? faCircleExclamation : faInfoCircle)
              }
            />
          </div>
        </div>
        <div className="flex flex-col gap-1 py-5 z-10">
          {title && <Text weight="bold">{title}</Text>}
          {children && <Text>{children}</Text>}
        </div>
      </div>
      {lowerContent && (
        <Text as="span" weight="bold">
          <div
            className={clsx("flex py-4 pr-5 pl-12 gap-3 border-t", {
              "bg-redA-2 border-redA-4": severity === "error",
              "bg-yellowA-2  border-yellow-4": severity === "warning",
              "bg-greenA-2  border-greenA-4": severity === "success",
            })}
          >
            {lowerContent}
          </div>
        </Text>
      )}
    </div>
  );
}
