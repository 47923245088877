import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import type { Hash } from "viem"
import { AppDispatch } from "../../store"

export enum ToastType {
  REJECTED = "REJECTED",
  REVERTED = "REVERTED",
  SUCCESS = "SUCCESS",
}

export interface ToastContent {
  code?: number
  details?: string
  func: string
  hash: Hash
  message?: string
  type?: ToastType
}

export interface ToasterState {
  toast?: ToastContent
  open?: boolean
}

const initialState: ToasterState = {}

const toasterSlice = createSlice({
  name: "toaster",
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastContent>) => {
      state.toast = action.payload
    },
    openChange: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
  },
})

interface WithDispatch {
  dispatch: AppDispatch
}
export const { addToast, openChange } = toasterSlice.actions

const notify = ({ dispatch, ...toast }: ToastContent & WithDispatch) => {
  dispatch(openChange(false))
  setTimeout(() => {
    dispatch(openChange(true))
    dispatch(addToast(toast))
  }, 100)
}

export const notifyError = (notifyProps: ToastContent & WithDispatch) => {
  notify(notifyProps)
}
export const notifySuccess = ({
  hash,
  func,
  dispatch,
}: Pick<ToastContent, "hash" | "func"> & WithDispatch) => {
  notify({ hash, func, dispatch, type: ToastType.SUCCESS })
}

export default toasterSlice.reducer
