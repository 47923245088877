"use client";

import Link from "next/link";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAccount } from "wagmi";

import { Alert } from "@/common/components/Alert";
import { ToastContent } from "@/app/features/toaster";
import { IconButton } from "@radix-ui/themes";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";

interface SuccessToastProps {
  toast: ToastContent;
}

export default function SuccessToast({ toast }: SuccessToastProps) {
  const { chain } = useAccount();

  return (
    <Alert
      lowerContent={
        <div className="flex items-center gap-2">
          <Link
            href={`${chain?.blockExplorers?.default.url}/tx/${toast.hash}`}
            target="_blank"
          >
            Transaction details
          </Link>
          <Link
            href={`${chain?.blockExplorers?.default.url}/tx/${toast.hash}`}
            target="_blank"
          >
            <IconButton asChild variant="soft">
              <span>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </IconButton>
          </Link>
        </div>
      }
      severity="success"
      title={`${toast.func} complete`}
    />
  );
}
