"use client";

import { ToastContent } from "@/app/features/toaster";
import { Alert } from "@/common/components/Alert";

interface ErrorToastProps {
  toast: ToastContent;
}

export default function ErrorToast({ toast }: ErrorToastProps) {
  return (
    <Alert severity="error" title={`Transaction ${toast.type}`}>
      {toast.code ? `${toast.code}: ${toast.message}` : toast.message}
    </Alert>
  );
}
