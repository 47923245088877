import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  SortTokenParams,
  SortTokenReturn,
  TokenParams,
  TokenReturn,
} from "@/app/services/types";
import tokenList from "@/app/tokenList.json";
import {
  filterTokens,
  getSortedTokensByQuery,
  tokenComparator,
} from "@/common/utils";

export const tokensApi = createApi({
  reducerPath: "tokensApi",
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    getTokens: builder.query<TokenReturn, TokenParams>({
      queryFn: async ({ chainId }) => {
        const tokens = tokenList.tokens.filter(
          (token) => token.chainId === chainId,
        ) as TokenReturn["tokens"];

        return {
          data: { tokens },
        };
      },
    }),
    getSortedTokens: builder.query<SortTokenReturn, SortTokenParams>({
      queryFn: async ({ tokens, query = "", customTokens = [], balances }) => {
        const filteredTokens = filterTokens(tokens, query);
        const filteredCustomTokens = filterTokens(customTokens, query);

        const sortedTokens = [...filteredTokens, ...filteredCustomTokens].sort(
          tokenComparator(balances),
        );

        const filteredSortedTokens = getSortedTokensByQuery(
          sortedTokens,
          query,
        );

        return {
          data: { tokens: filteredSortedTokens },
        };
      },
    }),
  }),
});

export const { useGetTokensQuery, useGetSortedTokensQuery } = tokensApi;
